.af-view .w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.af-view .af-class-container-2 {
  float: right;
}

.af-view .af-class-navbar_homepage {
  padding-top: 30px;
  padding-left: 70px;
  background-color: hsla(0, 0%, 86.7%, 0);
}

.af-view .af-class-nav-link {
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-nav-link-2 {
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-nav-link-3 {
  margin-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-apply_nav_link {
  width: 120px;
  margin-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.af-view .af-class-section_top {
  height: 600px;
  background-color: #fff;
  background-image: url('../../../public/images/45-South-with-Pisa-Range-dark.jpg'), url('../../../public/images/45-South-with-Pisa-Range.jpg');
  background-position: 0px 0px, 60% 0%;
  background-size: cover, cover;
  background-repeat: repeat, no-repeat;
}

.af-view .af-class-columns {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 197px;
}

.af-view .af-class-text-block {
  width: auto;
  max-width: 700px;
  padding-right: 0px;
  padding-left: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #fff;
  font-size: 50px;
  line-height: 55px;
  font-weight: 700;
  text-decoration: none;
}

.af-view .af-class-heading {
  max-width: 700px;
  margin-bottom: 15px;
  font-family: Emmeline, sans-serif;
  color: #fff;
  font-size: 44px;
  font-weight: 400;
}

.af-view .af-class-video_div {
  padding-top: 20px;
  padding-bottom: 25px;
}

.af-view .af-class-heading-2 {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 0px;
  font-family: Emmeline, sans-serif;
  color: #fff;
  font-size: 44px;
  font-weight: 400;
  text-decoration: none;
}

.af-view .af-class-column {
  margin-top: -48px;
  padding-right: 6px;
  padding-left: 15%;
}

.af-view .af-class-section_one {
  position: static;
  left: auto;
  right: auto;
  max-width: 100%;
  padding-top: 60px;
  padding-right: 15%;
  padding-left: 15%;
  float: none;
  clear: none;
}

.af-view .af-class-column-2 {
  padding-right: 0px;
  padding-left: 0px;
}

.af-view .af-class-heading-3 {
  margin-top: -1px;
  margin-bottom: 0px;
  font-family: Emmeline, sans-serif;
  font-weight: 400;
}

.af-view .af-class-heading-4 {
  margin-top: 0px;
  font-family: 'Pt serif web', sans-serif;
  font-size: 36px;
  font-weight: 700;
}

.af-view .af-class-paragraph {
  max-width: 700px;
  margin-bottom: 25px;
  padding-right: 40px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  text-align: left;
}

.af-view .af-class-button {
  display: inline-block;
  padding-right: 50px;
  padding-left: 50px;
  border: 3px solid #000;
  background-color: rgba(56, 152, 236, 0);
  font-family: Poppins, sans-serif;
  color: #000;
  font-weight: 700;
}

.af-view .af-class-homepage-top-img2 {
  position: static;
  display: inline-block;
  overflow: visible;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  float: none;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-section_two {
  position: static;
  left: 150px;
  right: 0px;
  margin-bottom: 93px;
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
  float: none;
}

.af-view .af-class-section_three {
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-heading-5 {
  margin-bottom: 0px;
  font-family: Emmeline, sans-serif;
  font-weight: 400;
  text-align: center;
}

.af-view .af-class-heading-6 {
  margin-top: 0px;
  margin-bottom: 23px;
  font-family: 'Pt serif web', sans-serif;
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
  text-align: center;
}

.af-view .af-class-grid {
  margin-top: 10px;
  padding-top: 0px;
  padding-right: 20%;
  padding-left: 20%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-flow: row;
  grid-column-gap: 14px;
  grid-row-gap: 2px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.af-view .af-class-image-4 {
  display: inline-block;
  float: none;
  text-align: left;
}

.af-view .af-class-section_four1 {
  margin-top: 40px;
  padding-top: 40px;
  padding-right: 10%;
  padding-left: 10%;
}

.af-view .af-class-div-block-3 {
  width: auto;
  height: 300px;
  background-image: url('../../../public/images/Have-a-question-img.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-div-block-4 {
  padding-top: 68px;
  padding-left: 46px;
}

.af-view .af-class-button-2 {
  display: inline-block;
  padding-right: 40px;
  padding-left: 40px;
  border: 3px solid #fff;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-section_footer {
  padding-top: 30px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-link {
  display: block;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: rgba(5, 5, 5, 0.71);
  font-weight: 400;
  text-decoration: none;
  font-size: 14px;
}

.af-view .af-class-heading-8 {
  margin-top: 12px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  text-align: left;
}

.af-view .af-class-text-block-2 {
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: rgba(5, 5, 5, 0.71);
  font-weight: 400;
  text-align: left;
  font-size: 14px;
}

.af-view .af-class-text-block-mobile {
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: rgba(5, 5, 5, 0.71);
  font-weight: 400;
  text-align: center;
  font-size: 14px;
}

.af-view .af-class-heading-9 {
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

.af-view .af-class-text-block-3 {
  position: static;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  margin-right: 16px;
  padding-top: 0px;
  float: left;
  clear: both;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  
}

.af-view .af-class-columns-2 {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-style: solid none none;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.28) #000 #000;
}

.af-view .af-class-image-5 {
  float: right;
}

.af-view .af-class-column-3 {
  margin-top: 0px;
}

.af-view .af-class-image-6 {
  display: none;
  max-width: 60%;
  margin-top: 16px;
  margin-bottom: 0px;
  margin-left: 15px;
  padding-right: 0px;
  padding-bottom: 19px;
  padding-left: 0px;
}

.af-view .af-class-image-7 {
  display: inline-block;
  margin-top: -45px;
  margin-left: 31px;
  padding-top: 0px;
}

.af-view .af-class-heading-10 {
  max-width: 500px;
  margin-top: 22px;
  font-family: 'Pt serif web', sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-column-4 {
  padding-right: 10px;
  padding-left: 0px;
}

.af-view .af-class-navbar_other {
  padding-top: 30px;
  padding-left: 70px;
  background-color: hsla(0, 0%, 86.7%, 0);
}

.af-view .af-class-nav_link_other_1 {
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-nav_link_other_1.w--current {
  border-style: none none solid;
  border-width: 2px;
  border-color: #000 #000 #fff;
  color: #fff;
}

.af-view .af-class-nav-link-2-copy {
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-nav-link-2-copy.w--current {
  border-bottom: 2px solid #fff;
  color: #fff;
}

.af-view .af-class-nav-link-2-copy.af-class-nav-link-10.w--current {
  border-bottom: 2px solid #fff;
  color: #fff;
  text-decoration: none;
}

.af-view .af-class-nav-link-3-copy {
  margin-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-nav-link-3-copy.w--current {
  border-bottom: 2px solid #fff;
  color: #fff;
}

.af-view .af-class-apply_nav_link_others {
  width: 120px;
  margin-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: #fff;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.af-view .af-class-img_other_nav {
  color: #fff;
}

.af-view .af-class-section {
  height: 300px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0.4))), url('../../../public/images/export_banner.png');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../../public/images/export_banner.png');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.af-view .af-class-section.af-class-header-seasonal-work {
  background-image: url('../../../public/images/DSC03877-1.png');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-section.af-class-header-about {
  margin-bottom: 0px;
  background-image: url('../../../public/images/header-about.png');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-section.af-class-header-map {
  margin-bottom: 0px;
  background-image: url('../../../public/images/header-map.png');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-section.af-class-header-contact {
  background-image: url('../../../public/images/header-contact.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-div-block-5 {
  padding-top: 25px;
  padding-right: 120px;
  padding-left: 120px;
}

.af-view .af-class-section-2 {
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.14);
}

.af-view .af-class-div-block-6 {
  min-height: 80vh; /* Full viewport height */
  padding: 0;
  background-image: url('../../../public/images/TrafficPath.png');
  background-position: top center; /* Prioritize the top of the image */
  background-size: contain; /* Scale the image proportionally to cover the block */
  background-repeat: no-repeat;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-div-block-7 {
  display: block;
  margin: auto;
  padding-top: 232px;
  padding-right: 250px;
  padding-left: 250px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.af-view .af-class-section-3 {
  padding-top: 0px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-heading-14 {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-left: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #333;
}

.af-view .af-class-paragraph-2 {
  margin-bottom: 15px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.af-view .af-class-columns-3 {
  padding-top: 15px;
}

.af-view .af-class-paragraph-3 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-paragraph-4 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-column-5 {
  padding-right: 20px;
}

.af-view .af-class-column-6 {
  padding-left: 20px;
}

.af-view .af-class-row-of-4 {
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-column-7 {
  padding-top: 65px;
}

.af-view .af-class-column-8 {
  padding-top: 65px;
}

.af-view .af-class-section-5 {
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-heading-16 {
  margin-bottom: 0px;
  padding-bottom: 5px;
  font-family: 'PT Serif', serif;
  color: #000;
  font-size: 22px;
  font-weight: 700;
}

.af-view .af-class-text-block-4 {
  margin-bottom: 5px;
  padding-top: 0px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.af-view .af-class-link-2 {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.af-view .af-class-column-11 {
  margin-bottom: 20px;
  padding-top: 0px;
}

.af-view .af-class-column-12 {
  padding-top: 0px;
}

.af-view .af-class-columns-4 {
  margin-bottom: 20px;
}

.af-view .af-class-heading-17 {
  font-family: 'Pt serif web', sans-serif;
  color: #000;
  font-weight: 700;
  text-align: center;
}

.af-view .af-class-container-3 {
  padding-bottom: 30px;
}

.af-view .af-class-div-block-4-copy {
  padding-top: 80px;
  padding-left: 46px;
}

.af-view .af-class-div-block-4-copy.af-class-cta-about {
  padding-top: 61px;
}

.af-view .af-class-div-block-3-copy {
  width: auto;
  height: 300px;
  background-image: url('../../../public/images/packhouse-overview.jpg');
  background-position: 50% 100%;
  background-size: cover;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-section-6 {
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-heading-13-_seasonal {
  width: 500px;
  margin-top: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}

.af-view .af-class-heading-12-seasonal {
  width: 500px;
  margin-bottom: 5px;
  font-family: Emmeline, sans-serif;
  color: #fff;
  font-size: 44px;
  font-weight: 400;
}

.af-view .af-class-nav-link-2-c {
  margin-right: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-section-2-seasonal {
  padding: 40px 15%;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.14);
}

.af-view .af-class-heading-19 {
  margin-bottom: 32px;
  font-family: 'PT Serif', serif;
  color: #000;
  line-height: 38px;
  text-align: left;
}

.af-view .af-class-paragraph-6 {
  padding-top: 0px;
  font-family: Poppins, sans-serif;
  color: #333;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.af-view .af-class-column-map {
  padding-left: 30px;
  padding-top: 10px;
  justify-content: center;
  align-self: center;
}

.af-view .af-class-paragraph-7 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-paragraph-8 {
  margin-top: 0px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-section-7 {
  min-height: 300px;
  padding: 40px 15%;
  background-image: url('../../../public/images/background-pattern-green.jpg');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: repeat;
}

.af-view .af-class-heading-21 {
  font-family: 'Pt serif web', sans-serif;
  color: #fff;
  font-size: 29px;
  text-align: center;
}

.af-view .af-class-text-block-5 {
  padding-bottom: 20px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  text-align: center;
}

.af-view .af-class-seasonal_apply_button_1 {
  position: static;
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding: 12px 60px;
  float: left;
  clear: none;
  border: 3px solid #fff;
  background-color: rgba(56, 152, 236, 0);
  font-family: Poppins, sans-serif;
  font-weight: 700;
}

.af-view .af-class-div-block-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  text-align: center;
}

.af-view .af-class-container-4 {
  padding-top: 40px;
}

.af-view .af-class-section-8 {
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-heading-22 {
  margin-top: 0px;
  padding-right: 22px;
  font-family: 'Pt serif web', sans-serif;
  color: #000;
  font-weight: 700;
}

.af-view .af-class-text-block-6 {
  margin-bottom: 15px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.af-view .af-class-heading-23 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  line-height: 28px;
}

.af-view .af-class-paragraph-10 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-text-block-7 {
  padding-bottom: 10px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-tab-link-tab-1 {
  border-bottom: 1px solid #000;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  color: #000;
  font-weight: 500;
}

.af-view .af-class-tab-link-tab-1.w--current {
  border-bottom: 1px solid #069110;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  color: #069110;
  font-weight: 500;
}

.af-view .af-class-tab-link-tab-2 {
  border-bottom: 1px solid #000;
  background-color: #fff;
  font-family: Poppins, sans-serif;
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

.af-view .af-class-tab-link-tab-2.w--current {
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #069110;
  background-color: #fff;
  color: #069110;
}

.af-view .af-class-section-9 {
  display: block;
  padding-top: 80px;
  padding-right: 15%;
  padding-left: 15%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-section-9.af-class-section-9ab {
  margin-bottom: 60px;
  padding-top: 60px;
}

.af-view .af-class-heading-24 {
  margin-bottom: 20px;
  padding-right: 0px;
  padding-left: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #000;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

.af-view .af-class-paragraph-11 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-column-16 {
  padding-left: 0px;
}

.af-view .af-class-tabs-content {
  padding-top: 20px;
}

.af-view .af-class-text-block-9 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.af-view .af-class-text-block-10 {
  font-weight: 700;
}

.af-view .af-class-section-10 {
  margin-top: 32px;
  padding: 0px 0%;
}

.af-view .af-class-image-10 {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  float: none;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.af-view .af-class-div-block-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-section-11 {
  margin-left: 0px;
  padding: 40px 15%;
}

.af-view .af-class-heading-25 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Emmeline, sans-serif;
  color: #000;
  font-weight: 400;
}

.af-view .af-class-heading-26 {
  margin-top: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #000;
  font-size: 32px;
  line-height: 36px;
}

.af-view .af-class-paragraph-12 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}

.af-view .af-class-list-item {
  position: static;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-list-item-2 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-list-item-3 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-section-12 {
  padding: 40px 15%;
}

.af-view .af-class-div-block-10 {
  background-image: url('../../../public/images/Have-a-question-img.jpg');
  background-position: 0px 0px;
  background-size: cover;
}

.af-view .af-class-heading-27 {
  display: block;
  max-width: 500px;
  margin-right: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #fff;
  font-size: 30px;
}

.af-view .af-class-div-block-11 {
  display: block;
  height: 300px;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 60px;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  background-image: url('../../../public/images/cherries-on-tree-small-dark.jpg');
  background-position: 50% 100%;
  background-size: cover;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-div-block-11.af-class-seasonal-work-cta {
  background-image: url('../../../public/images/packhouse-overview.jpg');
  background-position: 100% 100%;
  background-size: cover;
}

.af-view .af-class-footer_action_button {
  display: block;
  margin-top: 19px;
  padding: 12px 60px;
  float: left;
  clear: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 3px solid #fff;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-footer_image {
  display: block;
  max-width: 80%;
  margin-top: -23px;
  margin-left: 0px;
  padding-left: 21px;
}

.af-view .af-class-image-12 {
  display: none;
  overflow: visible;
  margin-top: -56px;
  margin-bottom: 0px;
  padding-right: 120px;
  float: right;
  clear: none;
}

.af-view .af-class-column-19 {
  padding-left: 0px;
}

.af-view .af-class-section-2-exports {
  padding: 40px 14%;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.14);
}

.af-view .af-class-section-2-contact {
  padding: 40px 15%;
  box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.14);
}

.af-view .af-class-heading-28 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: Poppins, sans-serif;


}

.af-view .af-class-text-block-11 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.af-view .af-class-heading-29 {
  margin-bottom: 5px;
  font-family: Poppins, sans-serif;
}

.af-view .af-class-section-13 {
  padding-top: 10px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-column-20 {
  height: 100%;
  padding-top: 0%;
  padding-bottom: 0px;
  padding-left: 0px;
  background-color: #475cbd;
}

.af-view .af-class-column-21 {
  height: 100%;
  background-color: #323b88;
}

.af-view .af-class-heading-30 {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #fff;
}

.af-view .af-class-text-block-12 {
  display: block;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.af-view .af-class-column-22 {
  padding-top: 37px;
  padding-right: 0%;
}

.af-view .af-class-image-13 {
  width: 45%;
  height: 50%;
  max-width: 95%;
  margin-right: 5%;
  margin-left: 35%;
}

.af-view .af-class-columns-5 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.af-view .af-class-column-23 {
  padding-top: 35px;
  padding-left: 10%;
}

.af-view .af-class-section-5-contact {
  padding-top: 40px;
  padding-right: 15%;
  padding-left: 15%;
}

.af-view .af-class-column-24 {
  padding-top: 35px;
  padding-left: 10%;
}

.af-view .af-class-column-25 {
  position: static;
  padding-right: 0px;
  padding-left: 0px;
  clear: none;
}

.af-view .af-class-column-26 {
  padding-right: 0px;
  padding-left: 60px;
}

.af-view .af-class-container-2_nav_2 {
  float: right;
}

.af-view .af-class-bold-text {
  color: #333;
}

.af-view .af-class-image-15 {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-link-block-2 {
  padding-left: 35px;
}

.af-view .af-class-column-27 {
  position: relative;
  margin-top: 31px;
  padding-right: 0px;
  padding-left: 40px;
}

.af-view .af-class-image-16 {
  overflow: visible;
  padding-right: 0px;
  float: none;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-image-17 {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-image-18 {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-image-19 {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-column-29 {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0px;
  padding-left: 0px;
  clear: none;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.af-view .af-class-image-20 {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-image-21 {
  display: none;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-image-22 {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-image-23 {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-columns-6 {
  margin-top: 0px;
  padding-right: 0px;
}

.af-view .af-class-columns-7 {
  padding-top: 0px;
}

.af-view .af-class-column-32 {
  padding-top: 30px;
  padding-right: 0%;
}

.af-view .af-class-div-block-12 {
  height: 150px;
  padding-bottom: 2px;
}

.af-view .af-class-columns-8 {
  height: 150px;
  margin-top: 0px;
  padding-top: 0px;
  background-color: transparent;
}

.af-view .af-class-image-24 {
  margin-right: 10px;
  margin-bottom: 25px;
}

.af-view .af-class-div-block-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.youtube {
  max-width: 100%;
  vertical-align: top;
  padding-bottom: 20px;
  z-index: 2;
}
.af-view .af-class-lightbox-link {
  margin-top: 15px;
  padding-right: 10px;
}

.af-view .af-class-image-25 {
  margin-top: 30px;
}

.af-view .af-class-image-26 {
  margin-top: 30px;
}

.af-view .af-class-image-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.af-view .af-class-container-5 {
  padding-top: 0px;
}

.af-view .af-class-container-6 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-columns-9 {
  margin-top: 60px;
}

.af-view .af-class-seasonal_apply_button {
  display: block;
  margin-top: 19px;
  padding: 12px 60px;
  float: left;
  clear: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 3px solid #fff;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-seasonal_footer_image {
  display: block;
  max-width: 80%;
  margin-top: -23px;
  margin-left: 0px;
  padding-left: 21px;
}

.af-view .af-class-footer_link_apply {
  display: block;
  margin-bottom: 10px;
  font-family: Poppins, sans-serif;
  color: rgba(5, 5, 5, 0.71);
  font-weight: 400;
  text-decoration: none;
  font-size: 14px;
}

.af-view .af-class-column-33 {
  margin-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.af-view .af-class-column-34 {
  margin-top: 30px;
  padding-left: 30px;
}

.af-view .af-class-column-35 {
  padding-right: 0px;
  padding-left: 0px;
}

.af-view .af-class-column-36 {
  margin-top: 0px;
  padding-right: 40px;
}

.af-view .af-class-button-4-contact {
  display: inline-block;
  padding-right: 40px;
  padding-left: 40px;
  border: 3px solid #fff;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-img-seasonalwork-2 {
  display: block;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-img-seasonalwork-3 {
  display: block;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-image-21-copy {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-image-22-copy {
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-section-9aa {
  display: block;
  padding-top: 80px;
  padding-right: 15%;
  padding-left: 15%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-section-9aa.af-class-section-9ab {
  margin-bottom: 60px;
  padding-top: 60px;
}

.af-view .af-class-column-36-copy {
  margin-top: 0px;
  padding-right: 40px;
}

.af-view .af-class-section-9aa-copy {
  display: block;
  padding-top: 80px;
  padding-right: 15%;
  padding-left: 15%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-section-9aa-copy.af-class-section-9ab {
  margin-bottom: 60px;
  padding-top: 60px;
}

.af-view .af-class-column-36-copy-copy {
  margin-top: 0px;
  padding-right: 40px;
}

.af-view .af-class-heading-24-copy {
  margin-bottom: 20px;
  padding-right: 0px;
  padding-left: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #000;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

.af-view .af-class-paragraph-13 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-columns-10 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.af-view .af-class-heading-31 {
 
  font-family: 'PT Serif', serif;
  font-size: 32px;
  line-height: 32px;
}

.af-view .af-class-paragraph-14 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-text-block-13 {
  margin-bottom: 32px;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  line-height: 27px;
  text-align: center;
}

.af-view .af-class-div-block-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-button-3 {
  height: 45px;
  min-width: 144px;
  padding-bottom: 9px;
  border-style: solid;
  border-width: 2px;
  border-color: #53995b;
  background-color: rgba(56, 152, 236, 0);
  font-family: Poppins, sans-serif;
  color: #53995b;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  text-align: center;
}

.af-view .af-class-heading-19-copy {
  margin-bottom: 16px;
  margin-left: 10px;
  font-family: 'PT Serif', serif;
  color: #000;
  line-height: 38px;
  text-align: left;
}

.af-view .af-class-columns-11 {
  margin-bottom: 32px;
}

.af-view .af-class-text-block-14 {
  color: rgba(5, 5, 5, 0.7);
  text-align: center;
}

.af-view .af-class-link-3 {
  color: rgba(5, 5, 5, 0.7);
}

.af-view .af-class-heading-19-copy-copy {
  margin-bottom: 16px;
  margin-left: 0px;
  font-family: 'PT Serif', serif;
  color: #000;
  line-height: 38px;
  text-align: left;
}

.af-view .af-class-heading-19-copy-copy {
  margin-bottom: 16px;
  margin-left: 0px;
  font-family: 'PT Serif', serif;
  color: #000;
  line-height: 38px;
  text-align: left;
}

.af-view .af-class-image-33 {
  min-width: 100%;
}

.af-view .af-class-link-4 {
  color: #333;
}

.af-view .af-class-link-5 {
  color: #333;
}

.af-view .af-class-paragraph-15 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-heading-32 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-paragraph-16 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-section-14 {
  min-height: 380px;
  padding-right: 0%;
  padding-left: 0%;
  background-image: url('../../../public/images/export_mid_banner.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.af-view .af-class-paragraph-17 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-paragraph-18 {
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-paragraph-19 {
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-column-39 {
  padding-right: 0px;
  padding-left: 0px;
}

.af-view .af-class-column-40 {
  padding-right: 0px;
  padding-left: 10px;
}

.af-view .af-class-section-15 {
  padding-right: 14%;
  padding-left: 14%;
}

.af-view .af-class-paragraph-20 {
  margin-top: 16px;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-section-16 {
  min-height: 383px;
  margin-top: 32px;
  margin-bottom: 32px;
  background-image: url('../../../public/images/Group-18.png');
  background-position: 50% 50%;
  background-size: cover;
}

.af-view .af-class-heading_about_top {
  margin-top: 33px;
  margin-bottom: 20px;
  padding-left: 0px;
  font-family: 'Pt serif web', sans-serif;
  color: #333;
}

.af-view .af-class-column_export {
  margin-top: 40px;
  padding-top: 0px;
}

.af-view .af-class-export_img {
  position: static;
  display: inline-block;
  overflow: visible;
  max-width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  float: none;
  box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
}

.af-view .af-class-text-block-13-copy {
  margin-bottom: 32px;
  font-family: Poppins, sans-serif;
  font-size: 17px;
  line-height: 27px;
  text-align: left;
}

.af-view .af-class-season_rec_header {
  font-family: 'Pt serif web', sans-serif;
  color: #fff;
  font-size: 29px;
  text-align: center;
}

.af-view .af-class-button-3-copy {
  display: none;
  height: 45px;
  min-width: 144px;
  padding-bottom: 9px;
  border-style: solid;
  border-width: 2px;
  border-color: #53995b;
  background-color: rgba(56, 152, 236, 0);
  font-family: Poppins, sans-serif;
  color: #53995b;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  text-align: center;
}

.af-view .af-class-mad_campers_para {
  display: none;
  font-family: Poppins, sans-serif;
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-column-41 {
  display: none;
}

.af-view .af-class-contact_us_btn_2 {
  display: block;
  margin-top: 19px;
  padding: 12px 60px;
  float: left;
  clear: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 3px solid #fff;
  background-color: transparent;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 700;
}

.af-view .af-class-footer_image_2 {
  display: block;
  max-width: 80%;
  margin-top: -23px;
  margin-left: 0px;
  padding-left: 21px;
}

.af-view .af-class-column-42 {
  padding-top: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.af-view .af-class-image-34 {
  height: auto;
}

.af-view .af-class-season_apply_blurb {
  padding-bottom: 20px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .af-view .af-class-navbar_homepage {
    padding-top: 20px;
    padding-left: 25px;
  }

  .af-view .af-class-nav-link {
    margin-right: 0px;
    margin-left: 0px;
    font-weight: 400;
    text-align: left;
  }

  .af-view .af-class-nav-link-2 {
    margin-right: 0px;
    margin-left: 0px;
    font-weight: 400;
    text-align: left;
  }

  .af-view .af-class-nav-link-3 {
    margin-right: 0px;
    margin-left: 0px;
    font-weight: 400;
    text-align: left;
  }

  .af-view .af-class-apply_nav_link {
    position: relative;
    display: block;
    width: 120px;
    margin-right: 0px;
    margin-left: 0px;
    float: none;
    clear: none;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: center;
  }

  .af-view .af-class-section_top {
    height: 540px;
  }

  .af-view .af-class-columns {
    padding-top: 127px;
    padding-left: 0px;
  }

  .af-view .af-class-text-block {
    font-size: 40px;
  }

  .af-view .af-class-heading {
    margin-bottom: 0px;
  }

  .af-view .af-class-video_div {
    padding-left: 30px;
  }

  .af-view .af-class-heading-2 {
    margin-top: 12px;
  }

  .af-view .af-class-column {
    margin-top: 0px;
    padding-right: 0px;
    padding-left: 25px;
  }

  .af-view .af-class-section_one {
    position: static;
    max-width: 100%;
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 25px;
  }

  .af-view .af-class-column-2 {
    padding-right: 15px;
    padding-left: 0px;
  }

  .af-view .af-class-heading-3 {
    margin-top: 0px;
    padding-left: 5px;
    font-size: 34px;
  }

  .af-view .af-class-heading-4 {
    padding-left: 5px;
    font-size: 30px;
  }

  .af-view .af-class-paragraph {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-button {
    margin-left: 5px;
  }

  .af-view .af-class-homepage-top-img2 {
    display: block;
    margin-top: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-section_two {
    position: static;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 25px;
  }

  .af-view .af-class-section_three {
    padding-top: 40px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading-5 {
    margin-top: 0px;
  }

  .af-view .af-class-heading-6 {
    font-size: 32px;
  }

  .af-view .af-class-grid {
    margin-top: 0px;
    padding-top: 0px;
  }

  .af-view .af-class-section_four1 {
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-div-block-4 {
    padding-top: 61px;
    padding-left: 30px;
  }

  .af-view .af-class-icon {
    color: #fff;
    font-size: 32px;
  }

  .af-view .af-class-section_footer {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-link {
    margin-top: 10px;
  }

  .af-view .af-class-heading-8 {
    margin-top: 10px;
  }

  .af-view .af-class-columns-2 {
    border-style: solid none none;
    border-color: rgba(0, 0, 0, 0.28) #000 #000;
  }

  .af-view .af-class-image-5 {
    max-width: 100%;
    padding-top: 0px;
    float: right;
  }

  .af-view .af-class-image-6 {
    max-width: 60%;
    margin-top: 11px;
    margin-left: 5px;
  }

  .af-view .af-class-image-7 {
    margin-top: -51px;
    padding-top: 33px;
  }

  .af-view .af-class-navbar_other {
    padding-left: 25px;
  }

  .af-view .af-class-nav_link_other_1 {
    margin-right: 0px;
    margin-left: 0px;
  }

  .af-view .af-class-nav-link-2-copy {
    margin-right: 0px;
    margin-left: 0px;
  }

  .af-view .af-class-nav-link-2-copy.w--current {
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-nav-link-3-copy {
    margin-right: 0px;
    margin-left: 0px;
  }

  .af-view .af-class-apply_nav_link_others {
    margin-right: 0px;
    margin-left: 0px;
    text-align: left;
  }

  .af-view .af-class-div-block-5 {
    padding-right: 16px;
    padding-left: 25px;
  }

  .af-view .af-class-section-2 {
    padding-top: 25px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-div-block-6 {
    height: 340px;
  }

  .af-view .af-class-div-block-7 {
    padding-top: 200px;
    padding-right: 100px;
    padding-left: 100px;
  }

  .af-view .af-class-section-3 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading-14 {
    font-size: 28px;
  }

  .af-view .af-class-row-of-4 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-section-5 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-text-block-4 {
    font-size: 16px;
  }

  .af-view .af-class-div-block-4-copy {
    padding-top: 61px;
    padding-left: 30px;
  }

  .af-view .af-class-section-6 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading-13-_seasonal {
    font-size: 40px;
  }

  .af-view .af-class-nav-link-2-c {
    margin-right: 0px;
    margin-left: 0px;
  }

  .af-view .af-class-section-2-seasonal {
    padding-top: 26px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading-19 {
    width: 700px;
    margin-bottom: 20px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 28px;
  }

  .af-view .af-class-column-15 {
    padding-top: 20px;
    padding-left: 10px;
  }

  .af-view .af-class-section-7 {
    padding-bottom: 40px;
  }

  .af-view .af-class-container-4 {
    padding-top: 0px;
  }

  .af-view .af-class-section-8 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading-22 {
    padding-right: 0px;
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-section-9 {
    padding-top: 47px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-section-9.af-class-section-9ab {
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-heading-24 {
    margin-top: 20px;
    margin-bottom: 14px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 28px;
  }

  .af-view .af-class-paragraph-11 {
    padding-left: 0px;
  }

  .af-view .af-class-section-10 {
    padding: 30px 25px;
  }

  .af-view .af-class-image-10 {
    display: block;
  }

  .af-view .af-class-section-11 {
    padding: 0px 25px 30px;
  }

  .af-view .af-class-heading-25 {
    margin-top: 0px;
  }

  .af-view .af-class-heading-26 {
    font-size: 28px;
    line-height: 36px;
  }

  .af-view .af-class-section-12 {
    padding: 0px 25px;
  }

  .af-view .af-class-div-block-11 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-footer_image {
    display: block;
  }

  .af-view .af-class-image-12 {
    padding-right: 30px;
  }

  .af-view .af-class-section-2-exports {
    padding-top: 30px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-section-2-contact {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-section-13 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-column-22 {
    padding-top: 37px;
  }

  .af-view .af-class-image-13 {
    width: auto;
    height: auto;
    margin-left: 10px;
  }

  .af-view .af-class-section-5-contact {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-menu-button {
    color: #fff;
  }

  .af-view .af-class-menu-button.w--open {
    background-color: transparent;
  }

  .af-view .af-class-nav-menu {
    width: 40vw;
    margin-left: 0px;
    padding-left: 0px;
    background-color: #1f1f1f;
  }

  .af-view .af-class-column-25 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-26 {
    padding-left: 0px;
  }

  .af-view .af-class-link-block-2 {
    margin-top: 0px;
    padding-top: 45px;
    padding-left: 0px;
  }

  .af-view .af-class-column-27 {
    padding-left: 16px;
  }

  .af-view .af-class-image-19 {
    display: none;
  }

  .af-view .af-class-list {
    padding-left: 20px;
  }

  .af-view .af-class-image-23 {
    display: inline-block;
  }

  .af-view .af-class-columns-6 {
    position: static;
    margin-top: 30px;
    padding-right: 0px;
  }

  .af-view .af-class-columns-9 {
    margin-top: 50px;
  }

  .af-view .af-class-seasonal_footer_image {
    display: block;
  }

  .af-view .af-class-footer_link_apply {
    margin-top: 10px;
  }

  .af-view .af-class-column-33 {
    margin-top: 0px;
  }

  .af-view .af-class-column-34 {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-left: 10px;
  }

  .af-view .af-class-column-35 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 41px;
    padding-left: 41px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .af-view .af-class-img-seasonalwork-2 {
    display: none;
  }

  .af-view .af-class-img-seasonalwork-3 {
    display: none;
  }

  .af-view .af-class-section-9aa {
    padding-top: 47px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-section-9aa.af-class-section-9ab {
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-section-9aa-copy {
    padding-top: 47px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-section-9aa-copy.af-class-section-9ab {
    display: block;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-heading-24-copy {
    margin-top: 20px;
    margin-bottom: 14px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 28px;
  }

  .af-view .af-class-nav-menu-2 {
    background-color: #1f1f1f;
  }

  .af-view .af-class-menu-button-2 {
    background-color: transparent;
  }

  .af-view .af-class-menu-button-2.w--open {
    background-color: transparent;
  }

  .af-view .af-class-menu-button-3 {
    background-color: transparent;
  }

  .af-view .af-class-menu-button-3.w--open {
    background-color: transparent;
  }

  .af-view .af-class-nav-menu-3 {
    background-color: #1f1f1f;
  }

  .af-view .af-class-nav-menu-4 {
    background-color: #1f1f1f;
  }

  .af-view .af-class-menu-button-4 {
    background-color: transparent;
  }

  .af-view .af-class-menu-button-4.w--open {
    background-color: transparent;
  }

  .af-view .af-class-nav-menu-5 {
    background-color: #1f1f1f;
  }

  .af-view .af-class-menu-button-5 {
    background-color: transparent;
  }

  .af-view .af-class-menu-button-5.w--open {
    background-color: transparent;
  }

  .af-view .af-class-heading-19-copy {
    width: 700px;
    margin-bottom: 20px;
    margin-left: 0px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 28px;
  }

  .af-view .af-class-heading-19-copy-copy {
    width: 700px;
    margin-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 28px;
  }

  .af-view .af-class-heading-19-copy-copy {
    width: 700px;
    margin-bottom: 20px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 28px;
  }

  .af-view .af-class-paragraph-16 {
    margin-bottom: 20px;
  }

  .af-view .af-class-section-14 {
    min-height: 250px;
  }

  .af-view .af-class-section-15 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading_about_top {
    font-size: 28px;
  }

  .af-view .af-class-export_img {
    display: block;
    margin-top: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-footer_image_2 {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .af-view .af-class-navbar_homepage {
    padding-top: 15px;
  }

  .af-view .af-class-apply_nav_link {
    margin-left: 0px;
  }

  .af-view .af-class-section_top {
    height: 500px;
  }

  .af-view .af-class-columns {
    padding-top: 165px;
  }

  .af-view .af-class-text-block {
    padding-right: 100px;
    font-size: 36px;
    line-height: 48px;
  }

  .af-view .af-class-heading {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .af-view .af-class-video_div {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-2 {
    font-size: 38px;
  }

  .af-view .af-class-column {
    padding-left: 25px;
  }

  .af-view .af-class-section_one {
    padding-top: 10px;
  }

  .af-view .af-class-column-2 {
    position: static;
  }

  .af-view .af-class-heading-3 {
    margin-top: 19px;
    padding-left: 0px;
  }

  .af-view .af-class-heading-4 {
    padding-left: 0px;
  }

  .af-view .af-class-paragraph {
    padding-left: 0px;
  }

  .af-view .af-class-button {
    margin-left: 0px;
  }

  .af-view .af-class-homepage-top-img2 {
    margin-top: 40px;
    padding-top: 0px;
  }

  .af-view .af-class-homepage-top-img2.af-class-homepage-img2 {
    display: none;
  }

  .af-view .af-class-section_two {
    padding-top: 0px;
  }

  .af-view .af-class-section_four1 {
    padding-top: 20px;
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-div-block-4 {
    padding-left: 20px;
  }

  .af-view .af-class-icon {
    color: #fff;
  }

  .af-view .af-class-heading-8 {
    margin-top: 5px;
    padding-top: 0px;
    text-align: center;
  }

  .af-view .af-class-text-block-3 {
    display: block;
    text-align: center;
  }

  .af-view .af-class-column-3 {
    text-align: center;
  }

  .af-view .af-class-image-6 {
    max-width: 40%;
    margin-top: 0px;
    margin-left: 25px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-image-7 {
    max-width: 80%;
  }

  .af-view .af-class-heading-10 {
    padding-right: 0px;
    font-size: 28px;
  }

  .af-view .af-class-column-4 {
    padding-right: 0px;
  }

  .af-view .af-class-div-block-5 {
    padding-right: 25px;
  }

  .af-view .af-class-div-block-6 {
    height: 300px;
  }

  .af-view .af-class-div-block-7 {
    padding-top: 130px;
  }

  .af-view .af-class-section-3 {
    padding-top: 30px;
  }

  .af-view .af-class-heading-14 {
    margin-bottom: 4px;
    font-size: 26px;
    line-height: 32px;
  }

  .af-view .af-class-paragraph-2 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-paragraph-3 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-paragraph-4 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-column-5 {
    font-size: 16px;
  }

  .af-view .af-class-column-6 {
    padding-left: 10px;
  }

  .af-view .af-class-row-of-4 {
    padding-top: 30px;
  }

  .af-view .af-class-column-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    padding-left: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-column-8 {
    display: none;
    padding-top: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-column-9 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-column-10 {
    display: none;
    padding-top: 15px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-section-5 {
    padding-top: 20px;
  }

  .af-view .af-class-heading-16 {
    text-align: center;
  }

  .af-view .af-class-text-block-4 {
    text-align: center;
  }

  .af-view .af-class-link-2 {
    display: block;
    text-align: center;
  }

  .af-view .af-class-column-11 {
    display: block;
    margin-top: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    clear: none;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .af-view .af-class-column-12 {
    margin-bottom: 20px;
    padding: 0px;
  }

  .af-view .af-class-container-3 {
    padding-bottom: 2px;
  }

  .af-view .af-class-div-block-4-copy {
    padding-left: 20px;
  }

  .af-view .af-class-column-13 {
    margin-bottom: 20px;
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-14 {
    padding-left: 102px;
  }

  .af-view .af-class-section-6 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-13-_seasonal {
    font-size: 36px;
  }

  .af-view .af-class-heading-12-seasonal {
    font-size: 40px;
  }

  .af-view .af-class-section-2-seasonal {
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading-19 {
    width: auto;
    margin-top: 0px;
    margin-bottom: 12px;
  }

  .af-view .af-class-paragraph-6 {
    margin-bottom: 14px;
    padding-top: 0px;
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-column-15 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-paragraph-7 {
    margin-top: 0px;
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-paragraph-8 {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-section-7 {
    padding-right: 40px;
    padding-left: 40px;
  }

  .af-view .af-class-heading-21 {
    font-size: 26px;
  }

  .af-view .af-class-text-block-5 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-section-8 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading-22 {
    margin-bottom: 16px;
    font-size: 26px;
    line-height: 36px;
  }

  .af-view .af-class-text-block-6 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-heading-23 {
    font-size: 17px;
  }

  .af-view .af-class-paragraph-10 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-text-block-7 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-section-9 {
    padding-top: 19px;
  }

  .af-view .af-class-heading-24 {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 26px;
  }

  .af-view .af-class-paragraph-11 {
    margin-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-section-10 {
    padding: 0px;
  }

  .af-view .af-class-image-10 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .af-view .af-class-section-11 {
    padding-top: 0px;
  }

  .af-view .af-class-heading-25 {
    font-size: 34px;
  }

  .af-view .af-class-heading-26 {
    font-size: 30px;
  }

  .af-view .af-class-paragraph-12 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-list-item {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-list-item-2 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-list-item-3 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-section-12 {
    padding-right: 25px;
    padding-left: 25px;
  }

  .af-view .af-class-heading-27 {
    margin-top: 0px;
    margin-right: 100px;
    margin-bottom: 0px;
  }

  .af-view .af-class-div-block-11 {
    padding-top: 62px;
    padding-left: 25px;
  }

  .af-view .af-class-column-17 {
    padding-left: 0px;
  }

  .af-view .af-class-column-18 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-image-12 {
    position: absolute;
    left: auto;
    top: auto;
    right: 0%;
    bottom: 67%;
    display: none;
    max-width: 40%;
    margin-top: 0px;
    margin-right: 5px;
    padding-right: 0px;
    float: none;
    clear: left;
  }

  .af-view .af-class-section-2-exports {
    padding-bottom: 0px;
  }

  .af-view .af-class-section-2-contact {
    padding-bottom: 30px;
  }

  .af-view .af-class-text-block-11 {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .af-view .af-class-section-13 {
    margin-bottom: 0px;
  }

  .af-view .af-class-column-21 {
    margin-bottom: 20px;
  }

  .af-view .af-class-heading-30 {
    font-size: 26px;
  }

  .af-view .af-class-column-22 {
    position: relative;
  }

  .af-view .af-class-image-13 {
    width: auto;
    margin-right: 5%;
    margin-left: 80px;
  }

  .af-view .af-class-section-5-contact {
    margin-top: 0px;
  }

  .af-view .af-class-menu-button {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .af-view .af-class-image-14 {
    max-width: 80%;
    margin-top: 5px;
  }

  .af-view .af-class-column-26 {
    padding-left: 10px;
  }

  .af-view .af-class-bold-text {
    font-size: 26px;
    line-height: 32px;
  }

  .af-view .af-class-image-15 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-link-block-2 {
    padding-top: 0px;
  }

  .af-view .af-class-column-27 {
    margin-top: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-image-16 {
    display: none;
  }

  .af-view .af-class-image-17 {
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .af-view .af-class-image-18 {
    display: none;
    overflow: visible;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-image-19 {
    display: none;
  }

  .af-view .af-class-column-29 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-image-20 {
    display: none;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px;
  }

  .af-view .af-class-image-21 {
    display: block;
    overflow: visible;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-columns-9 {
    display: none;
  }

  .af-view .af-class-column-34 {
    padding-left: 0px;
  }

  .af-view .af-class-column-36 {
    padding-right: 10px;
  }

  .af-view .af-class-map {
    margin-top: 29px;
  }

  .af-view .af-class-img-seasonalwork-2 {
    display: none;
  }

  .af-view .af-class-img-seasonalwork-3 {
    display: none;
  }

  .af-view .af-class-image-21-copy {
    display: block;
    overflow: visible;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .af-view .af-class-image-22-copy {
    display: none;
  }

  .af-view .af-class-section-9aa {
    padding-top: 19px;
  }

  .af-view .af-class-column-36-copy {
    padding-right: 10px;
  }

  .af-view .af-class-section-9aa-copy {
    padding-top: 19px;
  }

  .af-view .af-class-column-36-copy-copy {
    padding-right: 10px;
  }

  .af-view .af-class-heading-24-copy {
    padding-right: 0px;
    padding-left: 0px;
    font-size: 26px;
  }

  .af-view .af-class-image-28 {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .af-view .af-class-image-map {
    display: block;
    margin:20px;
    justify-content: center;
    align-self: center;
    width:max-content
  }

  .af-view .af-class-image-29 {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-image-30 {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-image-31 {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-image-32 {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }

  .af-view .af-class-heading-19-copy {
    width: auto;
    margin-top: 0px;
    margin-bottom: 12px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-heading-19-copy-copy {
    width: auto;
    margin-top: 0px;
    margin-bottom: 12px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-heading-19-copy-copy {
    width: auto;
    margin-top: 0px;
    margin-bottom: 12px;
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-37 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-image-33 {
    margin-bottom: 32px;
  }

  .af-view .af-class-column-38 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-40 {
    padding-left: 0px;
  }

  .af-view .af-class-heading_about_top {
    margin-bottom: 4px;
    font-size: 26px;
    line-height: 32px;
  }

  .af-view .af-class-export_img {
    margin-top: 40px;
    padding-top: 0px;
  }

  .af-view .af-class-export_img.af-class-homepage-img2 {
    display: none;
  }

  .af-view .af-class-season_rec_header {
    font-size: 26px;
  }

  .af-view .af-class-season_apply_blurb {
    font-size: 16px;
    line-height: 26px;
  }
}

@media screen and (max-width: 479px) {
  .af-view .af-class-navbar_homepage {
    padding-top: 10px;
    padding-left: 16px;
  }

  .af-view .af-class-apply_nav_link {
    margin-left: 0px;
  }

  .af-view .af-class-section_top {
    height: 460px;
  }

  .af-view .af-class-columns {
    padding-top: 110px;
  }

  .af-view .af-class-text-block {
    margin-bottom: 10px;
    padding-right: 0px;
    font-size: 30px;
    line-height: 40px;
  }

  .af-view .af-class-heading {
    margin-bottom: 0px;
    padding-left: 0px;
    font-size: 38px;
  }

  .af-view .af-class-video_div {
    padding: 0px;
  }

  .af-view .af-class-heading-2 {
    margin-top: 0px;
    margin-bottom: -17px;
    font-size: 34px;
  }

  .af-view .af-class-column {
    margin-top: 0px;
    padding-right: 16px;
    padding-bottom: 6px;
    padding-left: 16px;
  }

  .af-view .af-class-section_one {
    padding-top: 10px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-column-2 {
    position: static;
    padding-right: 0px;
  }

  .af-view .af-class-heading-3 {
    margin-top: 15px;
  }

  .af-view .af-class-heading-4 {
    margin-bottom: 10px;
    font-size: 26px;
    line-height: 34px;
  }

  .af-view .af-class-paragraph {
    padding-right: 0px;
    font-size: 16px;
  }

  .af-view .af-class-button {
    margin-bottom: 15px;
    padding-right: 90px;
    padding-left: 90px;
    float: none;
    clear: none;
    text-align: left;
  }

  .af-view .af-class-homepage-top-img2 {
    display: inline-block;
    margin-top: 24px;
    padding-top: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-section_two {
    padding-top: 0px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-section_three {
    padding-top: 10px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-6 {
    font-size: 26px;
    line-height: 34px;
  }

  .af-view .af-class-section_four1 {
    padding-top: 20px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-div-block-4 {
    padding-top: 41px;
    padding-left: 10px;
  }

  .af-view .af-class-button-2 {
    padding-right: 70px;
    padding-left: 70px;
    text-align: center;
  }

  .af-view .af-class-icon {
    color: #fff;
  }

  .af-view .af-class-section_footer {
    padding-top: 20px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-text-block-3 {
    padding-left: 0px;
    float: none;
    text-align: center;
  }

  .af-view .af-class-columns-2 {
    margin-top: 10px;
  }

  .af-view .af-class-image-5 {
    display: block;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    float: none;
    clear: left;
    text-align: center;
  }

  .af-view .af-class-image-6 {
    max-width: 80%;
    margin-top: 5px;
    margin-left: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-image-7 {
    display: none;
  }

  .af-view .af-class-heading-10 {
    max-width: none;
    font-size: 26px;
    line-height: 34px;
    text-align: left;
  }

  .af-view .af-class-column-4 {
    padding-top: 30px;
    padding-left: 0px;
  }

  .af-view .af-class-navbar_other {
    padding-left: 20px;
  }

  .af-view .af-class-div-block-5 {
    padding-right: 20px;
    padding-left: 20px;
  }

  .af-view .af-class-section-2 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-div-block-6 {
    height: 260px;
    box-shadow: 2px 2px 9px 0 rgba(0, 0, 0, 0.26);
  }

  .af-view .af-class-div-block-7 {
    padding-top: 104px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .af-view .af-class-section-3 {
    padding-top: 24px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-14 {
    padding-left: 0px;
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-paragraph-2 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-paragraph-3 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-column-5 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-6 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-row-of-4 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-column-7 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-9 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-section-5 {
    padding-top: 15px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-16 {
    text-align: center;
  }

  .af-view .af-class-text-block-4 {
    text-align: center;
  }

  .af-view .af-class-link-2 {
    display: block;
    clear: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .af-view .af-class-column-11 {
    padding-top: 5px;
    padding-right: 0px;
    padding-left: 0px;
    text-align: left;
  }

  .af-view .af-class-column-12 {
    display: block;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-heading-17 {
    font-size: 22px;
    line-height: 30px;
    text-align: left;
  }

  .af-view .af-class-container-3 {
    padding-bottom: 15px;
  }

  .af-view .af-class-div-block-4-copy {
    padding-top: 41px;
    padding-left: 10px;
  }

  .af-view .af-class-column-13 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-14 {
    display: block;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
  }

  .af-view .af-class-section-6 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-13-_seasonal {
    width: auto;
    font-size: 30px;
  }

  .af-view .af-class-heading-12-seasonal {
    width: auto;
    margin-bottom: 0px;
    font-size: 38px;
  }

  .af-view .af-class-section-2-seasonal {
    padding-top: 20px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-19 {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-paragraph-6 {
    padding-top: 0px;
  }

  .af-view .af-class-column-15 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-paragraph-8 {
    margin-bottom: 0px;
  }

  .af-view .af-class-section-7 {
    padding-right: 40px;
  }

  .af-view .af-class-heading-21 {
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-section-8 {
    padding-top: 30px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-22 {
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-tab-link-tab-1.w--current {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .af-view .af-class-tab-link-tab-2 {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .af-view .af-class-section-9 {
    padding-top: 31px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-24 {
    margin-top: 0px;
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-text-block-9 {
    font-size: 17px;
  }

  .af-view .af-class-text-block-10 {
    font-size: 17px;
  }

  .af-view .af-class-section-10 {
    display: none;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .af-view .af-class-section-11 {
    padding-right: 16px;
    padding-bottom: 20px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-26 {
    font-size: 26px;
  }

  .af-view .af-class-section-12 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-heading-27 {
    margin-right: 15px;
    font-size: 26px;
    line-height: 34px;
    text-align: left;
  }

  .af-view .af-class-div-block-11 {
    padding-left: 16px;
  }

  .af-view .af-class-footer_image {
    display: none;
  }

  .af-view .af-class-column-18 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-image-12 {
    bottom: 60%;
    display: none;
  }

  .af-view .af-class-section-2-exports {
    padding: 26px 16px 0px;
  }

  .af-view .af-class-section-2-contact {
    padding: 26px 16px 30px;
  }

  .af-view .af-class-heading-28 {
    font-size: 16px;
  }

  .af-view .af-class-text-block-11 {
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-heading-29 {
    font-size: 16px;
  }

  .af-view .af-class-section-13 {
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-column-20 {
    padding-top: 30px;
    padding-right: 0px;
    padding-bottom: 30px;
  }

  .af-view .af-class-heading-30 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    font-size: 24px;
  }

  .af-view .af-class-text-block-12 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    font-size: 16px;
  }

  .af-view .af-class-column-22 {
    padding-top: 0px;
    padding-bottom: 9px;
  }

  .af-view .af-class-image-13 {
    display: block;
    max-width: 13%;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0px;
  }

  .af-view .af-class-columns-5 {
    padding-top: 15px;
    padding-bottom: 0px;
  }

  .af-view .af-class-column-23 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-section-5-contact {
    padding-top: 30px;
  }

  .af-view .af-class-column-24 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
  }

  .af-view .af-class-image-14 {
    max-width: 75%;
    margin-top: 10px;
  }

  .af-view .af-class-bold-text {
    text-align: left;
  }

  .af-view .af-class-image-15 {
    display: none;
  }

  .af-view .af-class-link-block-2 {
    padding-left: 5px;
  }

  .af-view .af-class-column-27 {
    padding-right: 0px;
  }

  .af-view .af-class-list {
    padding-left: 18px;
  }

  .af-view .af-class-column-28 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-image-20 {
    display: none;
  }

  .af-view .af-class-image-21 {
    margin-bottom: 40px;
  }

  .af-view .af-class-column-30 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-31 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-column-32 {
    padding-top: 0px;
  }

  .af-view .af-class-seasonal_apply_button {
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-seasonal_footer_image {
    display: none;
  }

  .af-view .af-class-column-34 {
    margin-top: 20px;
    padding-right: 0px;
  }

  .af-view .af-class-column-36 {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-button-4-contact {
    padding-right: 70px;
    padding-left: 70px;
    text-align: center;
  }

  .af-view .af-class-section-9aa {
    padding-top: 31px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-column-36-copy {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-section-9aa-copy {
    padding-top: 31px;
    padding-right: 16px;
    padding-left: 16px;
  }

  .af-view .af-class-column-36-copy-copy {
    padding-right: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-heading-24-copy {
    margin-top: 0px;
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-paragraph-13 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-paragraph-14 {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-div-block-14 {
    margin-bottom: 32px;
  }

  .af-view .af-class-heading-19-copy {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-heading-19-copy-copy {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-heading-19-copy-copy {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-heading_about_top {
    padding-left: 0px;
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-export_img {
    display: inline-block;
    margin-top: 24px;
    padding-top: 0px;
    padding-left: 0px;
  }

  .af-view .af-class-season_rec_header {
    font-size: 22px;
    line-height: 30px;
  }

  .af-view .af-class-mad_campers_para {
    font-size: 16px;
    line-height: 26px;
  }

  .af-view .af-class-contact_us_btn_2 {
    display: block;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .af-view .af-class-footer_image_2 {
    display: none;
  }
}

.af-view #w-node-_82c6a114-f2c6-2195-91ab-bb5ea608f802-77304b1d {
  -ms-grid-column-align: center;
  justify-self: center;
}

.af-view #w-node-b79958f6-d668-4f2f-83ae-0b0fd0864a5f-77304b1d {
  -ms-grid-column-align: center;
  justify-self: center;
}

.af-view #w-node-_65a45660-7d58-17c7-4cc1-2d72ed66537e-77304b1d {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-column-align: center;
  justify-self: center;
}

.af-view #w-node-_7d98bf51-d92c-25ad-7fe5-aac53a63d9a2-77304b1d {
  -ms-grid-column-align: center;
  justify-self: center;
}

.af-view #w-node-c0f8e439-2ceb-cf59-8e88-545b12c77597-77304b1d {
  -ms-grid-column-align: center;
  justify-self: center;
}

.af-view #w-node-d2d8194b-5bfa-f966-a159-4ee66c81f214-77304b1d {
  -ms-grid-column-align: center;
  justify-self: center;
}

@font-face {
  font-family: 'Emmeline';
  src: url('../../../public/fonts/Emmeline-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Pt serif web';
  src: url('../../../public/fonts/PT_Serif-Web-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}